<template>
  <TaskListSelectPopover
    ref="parentRef"
    heading="Set parent"
    search-placeholder="Find a task"
    :accept-levels="[TaskLevel.TASK]"
    class="w-full"
    :board-id="boardId"
    :selected-task-id="selectedTaskId"
    :section-id="sectionId"
    :show-create-form="showCreateForm"
    v-bind="$attrs"
    @change="onSelect"
    @cancel="showCreateForm = false"
  >
    <template #footer>
      <div class="pr-2 pb-2">
        <UButton
          class="w-full flex justify-center"
          variant="soft"
          color="gray"
          @click="onShowCreateForm"
        >
          Create task
        </UButton>
      </div>
    </template>
    <slot />
  </TaskListSelectPopover>
</template>

<script lang="ts" setup>
import type { TaskItem } from '#task/types'
import { TaskLevel } from '#task/constant'

defineProps({
  selectedTaskId: {
    type: String as PropType<string | null>,
    default: null,
  },
  boardId: {
    type: String,
    required: true,
  },
  sectionId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'change', task: TaskItem | null): void
}>()

const parentRef = ref<{ open: () => void, close: () => void }> ()
const showCreateForm = ref(false)

const onShowCreateForm = () => {
  showCreateForm.value = true
}

const onSelect = (task: TaskItem | null) => {
  emit('change', task)
}

defineExpose({ open: () => (
  parentRef.value?.open()
) })
</script>
